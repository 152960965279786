import React, {Component} from 'react';
import Confetti from 'react-confetti'

import './App.css';

const images = [
  '/assets/images/1.jpg',
  '/assets/images/5.jpg',
  '/assets/images/2.jpg',
  '/assets/images/6.jpg',
  '/assets/images/3.jpg',
  '/assets/images/4.jpg',
];

function App() {
  return (
    <div className="App">
      <Confetti numberOfPieces="30"/>
      <div className="flex">
      <Gallery list={images}/>
      <Letter/>
      </div>
    </div>
  );
}

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(() => {
        const next = this.state.index === this.props.list.length - 1 ? {index: 0} : {index: this.state.index + 1};
        this.setState(next);
      }, 4000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    return (
      <div className="gallery-container">
          <img src={this.props.list[this.state.index]} alt="Us"/>
      </div>
    )
  }
}

const Letter = () =>
  <div className="letter-container">
    <h1>Happy birthday, Mom!</h1>
    <p>Thank you for being such a great mom :) You're one of the most caring people I know and I appreciate you so much. Things are getting pretty busy and I'll try to call you and Dad weekly but even if it slips my mind, please know that I love and care about you a lot.</p>
    <p>Even though it may not seem like it at times, I have built lots of good habits because of you and it has really helped me now that I'm independent and living on my own.</p>
    <p>You are such an important person to me and I'm glad you're my mom. I miss you and can't wait to visit for Thanksgiving. I love you and I hope you have a great day!</p>
    <p><span role="img" aria-label="heart">&#10084;&#65039;</span>Jason</p>
  </div>;

export default App;
